import store from "../../store/index";

export default {
  open() {
    store.commit("headerDropdown", { display: true });
  },

  close() {
    store.commit("headerDropdown", { display: false });
  },
};
