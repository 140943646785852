import { createRouter, createWebHistory } from 'vue-router'
import Labels from './labels'
import ListPromoter from './labelsPromoter'
import store from '../../store/index'
import Base from '../views/Base.vue'

import account from '../services/account'

const routes: Array<any> = [
  { name: "Login", path: '/login', component: () => import('../views/Login.vue') },
  {
    path: '/dashboard',
    name: 'Adm',
    component: Base,
    children: Labels,
    meta: { allowedTypes: ['admin', 'organizador'] }
  },
  { path: "/:catchAll(.*)", redirect: '/dashboard' },
  {
    path: '/dashboard_promoter',
    name: 'DashboardPromoter',
    component: Base,
    children: ListPromoter,
    meta: { allowedTypes: ['promoter','admin'] }
  }
]


const router = createRouter({

  history: createWebHistory(process.env.BASE_URL ? process.env.BASE_URL : ''),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('changeName', to.name);

  const userInfo = account.getInfo()
  const allowedTypes: any = to.meta.allowedTypes || [];

  if (!localStorage.getItem("token_meu_evento") && to.name != "Login") router.push({ path: '/login' });
  if (localStorage.getItem("token_meu_evento") && to.name == "Login") router.push({ path: '/' });


  if (allowedTypes.length === 0 || allowedTypes.includes(userInfo.type)) {
    // Usuário tem permissão para acessar a rota
    next();
  } else {
    // Usuário não tem permissão para acessar a rota
    localStorage.clear()
    next('/login');
  }
});

export default router
