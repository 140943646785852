import store from '../../store/index'


export default {
    open(data=[]){
        store.commit('modalValorPago',{
            display: true,
            valores: data
        })
    },
    close(){
        store.commit('modalValorPago', {
            display: false,
            valores: []
        });
    },
}