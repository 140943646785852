<template>
  <div class="flex flex-col z-50 w-full slide-top" style="background-color: #7e1d53;" v-if="expandir===false">
    <header class="py-4 shadow-sm px-7">
      <div class="flex flex-row">
        <img class="img rounded-full border-white border-2 w-16 h-16"
          :src="`https://santocartao-files.s3.amazonaws.com/event-pictures/${evento.id}.jpg`"
          url="https://meuevento.santocartao.com.br/images/no_image.jpg" />
        <div class="flex flex-col">
          <p class="font-bold text-white ml-5 mt-2 mb-1" style="font-size: 18px">{{ evento.nome }}</p>
          <small class="ml-5 text-white" v-if="faltamDias" style="font-size: 15px">Faltam <span class="p-1 rounded-lg"
              style="background-color: rgba(1, 1, 1, 0.7);">{{ faltamDias }}</span> dias</small>
          <small class="ml-5 text-white font-semibold" style="font-size: 15px" v-else>Evento finalizado</small>
        </div>
      </div>
      <div class="mt-5 mb-5" style="width: 100%; border-bottom: 1px solid rgba(190,190,190, 0.4);" />
      <div class="flex flex-row">
        <div class="flex">
          <div v-for="(item, index) in nomeDaAba" :key="index">
            <p v-if="item[0] === $store.state.name.replaceAll('/', ' > ')" class="text-white">
              <fa :icon="`${item[1]}`" size="lg" class="my-1 mr-2 ml-1" />
              <span class="font-bold">{{ $store.state.name.replaceAll('/', ' > ') }}</span>
            </p>
          </div>
        </div>
        <div class="flex ml-auto">
          <fa :icon="`bars`" size="lg" class="my-1 mr-2 ml-1 text-white" @click="expand" />
        </div>
      </div>
      
    </header>
  </div>

  <div v-if="expandir===true" class="flex flex-col px-7 py-4 w-full z-50 slide-top" style="background-color: #7e1d53; margin-top: -2px;" >
        <fa :icon="`xmark`" size="lg" class="my-1 mr-2 ml-1 text-white ml-auto" @click="expand" v-if="expandir===true" />
        <div class="mb-5">
      <button v-if="mostrarBotao"
          class="bg-transparent text-white"
          style="font-weight: bold; border: 1px solid white; border-radius: 10px; padding: 7px; padding-left: 25px; padding-right: 25px; font-size: 14px;" @click="voltarVersao">
          Voltar para versão anterior
      </button>
    </div>
        <div v-for="(item, index) in nomeDaAba" :key="index">
          <router-link v-if="item[2] !== false" :to="{ name: item[0]}" @click="expand" class="flex ">
            <p class="text-white mb-3" style="font-size: 19px;"> {{item[0]}}</p>
          </router-link>
        </div>                         
        <div class="mt-5 mb-5" style="width: 100%; border-bottom: 1px solid rgba(190,190,190, 0.4);" />

        <div class="flex flex-row">
        <div class="flex flex-col">
          <Button color="secondary" text="Trocar Evento" @click="changeEvent"
          class="button hover:bg-SC_Roxo" style="border-radius: 10px; font-size: 14px;"/>
        </div>
        <div class="flex ml-auto">
        <button @click="logout"
          aria-label="Notifications" aria-haspopup="true" class="text-white border border-white px-2 rounded-lg">
          Sair
        </button>
        </div>
      </div>
              
      </div>
</template>

<script>
import Button from "../Elements/Button.vue";
import { onMounted, ref, watch, inject} from 'vue';
import { useRouter } from 'vue-router';
import {Labels,ListMobile} from "../../router/labels.ts";
import LabelsPromoter from "../../router/labelsPromoter.ts";
import account from "../../services/account.ts";
import { useRoute } from "vue-router";
import { GET } from "@/services/api";

export default {
    name:'NavbarComponent',
    components: {
      Button,
    },
    setup(){
      const modalTrocaEventos = inject("modalTrocaEvento");
      const modalLogout = inject("modalLogout");
      const eventoInfo = inject("evento");
      const router = useRouter();
      const info = ref({});
      const evento = ref({});
      const faltamDias = ref('')
      const options = ref([]);
      const nomeDaAba = ref([])
      const expandir = ref(false)
      const route = useRoute();
      const loader = inject("loading");
      const mostrarBotao = ref(false)

      const verificaEventoBotaoLegado = async () => {
      try {
      loader.open()
      const getEvento = await GET(`event/funcionalidade/${eventoInfo.getEvento().id}`);
      
      getEvento.map((x) => {
        if(x.type === 'nova_funcionalidade')
          mostrarBotao.value = true
      })

      }
      catch (e){
        console.log(e)
      } finally {
        loader.close()
      }
    }

      const voltarVersao = async () => {
      try {
      loader.open()
      const getUser = await GET(`usuarios/getAllInfo/${account.getInfo().id}`);
      const userCripto = btoa(JSON.stringify(getUser))
      window.location.href = `https://meuevento.santocartao.com.br?login=${userCripto}`
      }
      catch (e){
        console.log(e)
      } finally {
        loader.close()
      }
    }

      const logout = () => {
        modalLogout.open()
      }

      const contaDiasEvento = () => {

        const dataEventoString = evento.value.data.replace(/\//g, '-');
        const partesData = dataEventoString.split('-');
        const dataFormatada = `${partesData[2]}-${partesData[1]}-${partesData[0]}`;

        const dataEvento = new Date(dataFormatada)
        const dataHoje = new Date()
        
        const dias = Math.floor( (dataEvento.getTime() - dataHoje.getTime()) / (1000 * 60 * 60 * 24));

        if(dias >= 0){
          faltamDias.value = dias
        }
      }

      const expand = () => {
      expandir.value = !expandir.value;
    };

      const changeEvent = () => {
        expand()
        modalTrocaEventos.open();
      };

      const eventoValues = () => {
        evento.value = eventoInfo.getEvento();

        if (eventoInfo.getEventoID() === 0) {
          modalTrocaEventos.open();
        }
      };

      onMounted(() => {
        verificaEventoBotaoLegado()
        const infoAccount = account.getInfo();

        options.value = [];
        nomeDaAba.value = [];

        if (infoAccount.type === "admin" || infoAccount.type === "organizador") {
          options.value = ListMobile;
        } else {
          options.value = LabelsPromoter;
        }

        options.value.map((x) => nomeDaAba.value.push([x.name, x.icon, x.show, x.path]))
        eventoValues();
        contaDiasEvento();
      });

      // watch(
      //   () => eventoInfo.getEventoID(),
      //   () => {
      //     eventoValues();
      //   }
      // );

      return { logout, router, info, changeEvent, evento, faltamDias, nomeDaAba, expandir, expand, route, voltarVersao, mostrarBotao }
    }
    
}
</script>

<style scoped>
/*@media only screen and (max-width: 800px) {
  .navbar {
    padding: 15px 0.75rem;
  }
  .button {
    padding: 3px 6px;
  }
}*/

.slide-top {
  animation: slideTop 0.5s ease forwards;
}

@keyframes slideTop {
  from {
    transform: translateY(-80%);
  }
  to {
    transform: translateY(0);
  }
}

.slide-bot {
  animation: slideBot 0.5s ease forwards;
}

@keyframes slideBot {
  from {
    transform: translateY(80%);
  }
  to {
    transform: translateY(0);
  }
}

.button {
  border: 0px;
}

</style>