<template>
  <div
    v-if="store.getters.modalCadastroPromoters.display"
    x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150"
    x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
  >
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full h-5/6 px-4 py-3 bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl"
      role="dialog" id="modal">
      <!-- Remove header if you don't want a close icon. Use modal body to place modal tile. -->
      <header class="flex justify-center items-center relative">
        <h1 class="font-semibold text-xl text-SC_Azul">
          Lista de promoters do evento {{ store.getters.modalCadastroPromoters.evento.nome }}
        </h1>
        <button @click="close"
          class="inline-flex items-center absolute right-0 justify-self-end w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
          aria-label="close">
          <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" role="img" aria-hidden="true">
            <path
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd" fill-rule="evenodd"></path>
          </svg>
        </button>
      </header>
      <!-- Modal body -->
      <div class="mt-4 mb-6 h-5/6 overflow-y-auto p-4 rounded-md">
        <Datatable :headers="headers" :data="data" :options="options" color="primary" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { ref, watch } from 'vue';
import Datatable from "../../components/Elements/Datatable.vue";

export default {
  name: 'ModalCadastroPromoters',

  components: {
    Datatable
  },

  setup() {
    const store = useStore();
    const data = ref([]);

    const close = () => {
      store.commit("modalCadastroPromoters", { display: false, evento: {} });
    };

    const addPromoter = (promoter) => {
      // adicionar promoter no evento
    };

    const headers = [
      { key: "Nome", value: "nome" },
      { key: "Documento ", value: "cpf", document: true },
      { key: "Opções ", value: "" },
    ];

    const options = [
      {
        icon: "circle-plus",
        color: "#7C1D55",
        title: "Adicionar promoter",
        action: (promoter) => addPromoter(promoter)
      },
    ];

    watch(
      () => [store.getters.modalCadastroPromoters.display],
      () => {
        if (store.getters.modalCadastroPromoters.display) {
          data.value = Object.assign([], store.getters.modalCadastroPromoters.evento.promoters);
        } else {
          data.value = [];
        }
      })

    return { store, close, data, headers, options };
  },
}
</script>