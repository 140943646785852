import store from '../../store/index'

const dados_pagamento = {
    valor: null,
    description: "",
    saldo: null,
    quantidade: null,
    data: "",
    id_status: null,
};

export default {
    open(promoter={}, data=dados_pagamento){
        store.commit('modalPagamento',{
            display: true,
            dados_pagamento: data,
            promoter: promoter
        })
    },
    close(){
        store.commit('modalPagamento', {
            display: false,
            dados_pagamento: {},
            promoter: {},
        });
    },
}