import store from '../../store/index'

export default {
    open(){
        store.commit('modalTrocaEvento',{
            display: true,
        })
    },
    close(){
        store.commit('modalTrocaEvento', {
            display: false,
        });
    },
}