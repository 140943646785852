<template>
  <div
    v-if="store.getters.modalPromoter.display"
    x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150"
    x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
  >
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full h-5/6 px-4 py-3 bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl"
      role="dialog" id="modal">
      <!-- Remove header if you don't want a close icon. Use modal body to place modal tile. -->
      <header class="flex justify-center items-center relative">
        <h1 class="font-semibold text-xl text-SC_Azul">
          {{ store.getters.modalPromoter.isEditing ? 'Editar promoter' : 'Adicionar novo promoter' }}
        </h1>
        <button @click="close"
          class="inline-flex items-center absolute right-0 justify-self-end w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
          aria-label="close">
          <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" role="img" aria-hidden="true">
            <path
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd" fill-rule="evenodd"></path>
          </svg>
        </button>
      </header>
      <!-- Modal body -->
      <div class="mt-4 mb-6 h-5/6 overflow-y-auto bg-SC_Light_Gray p-4 rounded-md">
        <div :class="`grid ${isMobile ? 'grid-cols-1 mb-3' : 'grid-cols-2 mb-6'} gap-3`">
          <Input
            type="default"
            placeholder="Nome"
            id="nome"
            v-model="data.nome"
            :readonly="store.getters.modalPromoter.isEditing"
          />
          <Input
            type="cpf"
            placeholder="CPF"
            id="cpf"
            v-model="data.doc"
            :readonly="store.getters.modalPromoter.isEditing"
            :toBlur="() => procurarPromoter()"
          />
        </div>
        <div :class="`grid ${isMobile ? 'grid-cols-1 mb-3' : 'grid-cols-3 mb-6'} gap-3`">
          <Input
            type="default"
            placeholder="RG"
            id="rg"
            v-model="data.rg"
          />
          <Input
            type="phone"
            placeholder="Telefone"
            id="telefone"
            v-model="data.telefone"
          />
          <Input
            type="tax"
            placeholder="Comissão"
            id="comissao"
            v-model="data.porcentagem"
          />
        </div>
        <div :class="`grid grid-cols-1 ${isMobile ? 'mb-3' : 'mb-6'} gap-3`">
          <Input
            type="default"
            placeholder="Email"
            id="email"
            v-model="data.email"
            :readonly="store.getters.modalPromoter.isEditing"
          />
        </div>
        <div :class="`grid grid-cols-1 ${isMobile ? 'mb-3' : 'mb-6'} gap-3`">
          <div class="flex justify-start items-center mx-3 text-md">
            <input
              type="checkbox"
              id="cancel_vouchers"
              v-model="data.perm_cancel"
            />
            <label class="text-SC_Gray ml-2" for="cancel_vouchers">
              Permitir cancelamento de vouchers (válido p/ {{ store.getters.evento.nome}})
            </label>
          </div>
        </div>
        <div :class="`grid grid-cols-1 ${isMobile ? 'mb-3' : 'mb-6'} gap-3`">
          <div class="flex justify-start items-center mx-3">
            <input
              type="checkbox"
              id="pag_vendas"
              v-model="data.perm_visualiza_qrcode"
            />
            <label class="text-SC_Gray ml-2" for="pag_vendas">
              Criar página personalizado para vendas
            </label>
          </div>
        </div>
        <div :class="`grid grid-cols-1 ${isMobile ? 'mb-3' : 'mb-6'} gap-3`">
          <div class="flex justify-start items-center mx-3">
            <input
              type="checkbox"
              id="link_promoter"
              v-model="data.create_link_promoter"
            />
            <label class="text-SC_Gray ml-2" for="checkbox">
              Criar link de venda
            </label>
          </div>

          <Input
            v-if="data.create_link_promoter"
            type="default"
            placeholder="Link"
            id="link_promoter"
            v-model="data.link_promoter"
          />
        </div>
        <div :class="`grid ${isMobile ? 'grid-cols-1 mb-3' : 'grid-cols-2 mb-6'} gap-3`">
          <Input
            type="default"
            placeholder="Instagram"
            id="instagram"
            v-model="data.instagram"
          />
          <Input
            type="default"
            placeholder="Facebook"
            id="facebook"
            v-model="data.facebook"
          />
        </div>
        <div :class="`grid ${isMobile ? 'grid-cols-1 mb-3' : 'grid-cols-3 mb-6'} gap-3`">
          <div class="col-start-1 col-end-3">
            <Input
              type="default"
              placeholder="Logradouro (rua)"
              id="logradouro"
              v-model="data.logradouro"
            />
          </div>
          <Input
            type="number"
            placeholder="Número"
            id="numero"
            v-model="data.numero"
          />
        </div>
        <div :class="`grid ${isMobile ? 'grid-cols-1 mb-3' : 'grid-cols-2 mb-6'} gap-3`">
          <Input
            type="default"
            placeholder="Complemento"
            id="complemento"
            v-model="data.complemento"
          />
          <Input
            type="cep"
            placeholder="CEP"
            id="cep"
            v-model="data.cep"
          />
        </div>
        <div :class="`grid ${isMobile ? 'grid-cols-1 mb-3' : 'grid-cols-3 mb-6'} gap-3`">
          <Input
            type="default"
            placeholder="Cidade"
            id="cidade"
            v-model="data.cidade"
          />
          <Input
            type="default"
            placeholder="Bairro"
            id="bairro"
            v-model="data.bairro"
          />
          <Input
            type="default"
            placeholder="UF"
            id="uf"
            v-model="data.uf"
          />
        </div>

        <div class="flex items-center justify-between">
          <Button @click="close" color="red-500" text="Cancelar" outline />
          <Button @click="salvar" color="SC_Green" text="Salvar" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch } from 'vue';
import Input from '../../components/Elements/Input.vue';
import Button from '../../components/Elements/Button.vue';
import { isEmptyPromoter, isEmail, isLink } from '../../services/validate';
import { POST, PUT } from '../../services/api';
import account from '../../services/account';
import { cpf } from 'cpf-cnpj-validator';

export default {
  name: 'ModalPromoter',

  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  components: {
    Input,
    Button
  },

  setup() {
    const promoter = {
      nome: "",
      doc: "",
      rg: "",
      email: "",
      telefone: "",
      porcentagem: "",
      instagram: "",
      facebook: "",
      logradouro: "",
      numero: "",
      complemento: "",
      cep: "",
      cidade: "",
      bairro: "",
      uf: "",
      link_promoter: "",
      perm_cancel: true,
      create_link_promoter: true,
      perm_visualiza_qrcode: true,
      evento_id: 0,
      usuario_id: 0,
    };

    const store = useStore();
    const data = ref(promoter);
    const promoterAlreadyCreated = ref(false);
    const id_promoters_usuario = ref(null);

    const close = () => {
      store.commit("modalPromoter", { display: false, isEditing: false, promoter: {} });
    };

    const procurarPromoter = async () => {
      try {
        // store.commit('loading', true);
        const res = await POST('promoter_usuario/filter', { cpf: data.value.doc });
        if (res.data.length > 0) {
          data.value = {
            ...res.data[0],
            doc: res.data[0]?.promoter.documento,
            perm_cancel: res.data[0]?.vinculo.perm_cancel,
            perm_visualiza_qrcode: res.data[0]?.vinculo.perm_visualiza_qrcode,
            create_link_promoter: res.data[0]?.vinculo.perm_link_promoter,
            link_promoter: res.data[0]?.vinculo.link_promoter,
          };
          id_promoters_usuario.value = res.data[0]?.vinculo.id_promoters_usuario;
          promoterAlreadyCreated.value = true;
          delete data.value.vinculo;
          delete data.value.id;
          delete data.value.promoters_id;
          delete data.value.created_at;
          delete data.value.updated_at;
          delete data.value.deleted_at;
          delete data.value.promoter;
        }
      } catch (error) {
        showAlert("Erro", error, 'danger');
      }
      // finally {
      //   // store.commit('loading', false);
      // }
    };

    const salvar = async () => {
      if (!store.getters.modalPromoter.isEditing) {
        // Cadastrar novo promoter

        const accountInfo = account.getInfo();

        const payload = {
          ...data.value,
          evento_id: store.getters.evento.id,
          usuario_id: accountInfo.id,
        };

        // validar os dados
        const validate = isEmptyPromoter(payload);
        const emailValidate = isEmail(payload.email);
        const cpfValidate = cpf.isValid(payload.doc);
        const linkValidate = isLink(payload.link_promoter);
        const eventoUsuarioValidate = payload.evento_id > 0 && payload.usuario_id > 0;
        const fullValidate = payload.create_link_promoter
          ? !validate && emailValidate && cpfValidate && eventoUsuarioValidate && linkValidate
          : !validate && emailValidate && cpfValidate && eventoUsuarioValidate

        if (fullValidate) {
          try {
            store.commit('loading', true);
            const url = promoterAlreadyCreated.value
              ? `promoter_usuario/update_promoter_usuario/${id_promoters_usuario.value}`
              : 'promoter_usuario';
            const resPromoter = await POST(url, payload);
            close();
            showAlert('Sucesso', `Promoter ${payload.nome} criado com sucesso!`, 'success');
            store.commit('updateTablePromoter', true);
          } catch (error) {
            showAlert("Erro", error, 'danger');
          } finally {
            store.commit('loading', false);
          }
        } else {
          let message = "";
          if (!emailValidate) message = 'Digite um email válido!';
          if (!cpfValidate) message = 'Digite um CPF válido!';
          if (!eventoUsuarioValidate) message = 'Evento ou usuário inválido!';
          if (!linkValidate) message = 'Link do promoter deve conter apenas letras minúsculas, números ou "-" e "_"!';
          if (validate) message = validate;

          showAlert('Aviso', message, 'warning');
        }
      } else {
          // Editar promoter existente
          const validate = isEmptyPromoter(data.value);
          const linkValidate = isLink(data.value.link_promoter);
          const fullValidate = data.value.create_link_promoter ? !validate && linkValidate : !validate;

          if (fullValidate) {
            try {
              store.commit('loading', true);
              const resPromoter = await PUT(
                `promoter_usuario/${store.getters.modalPromoter.promoter.id}/organizador`,
                data.value
              );
              close();
              showAlert('Sucesso', `Promoter ${data.value.nome} editado com sucesso!`, 'success');
              store.commit('updateTablePromoter', true);
            } catch (error) {
              showAlert("Erro", error, 'danger');
            } finally {
              store.commit('loading', false);
            }
          } else {
            showAlert('Aviso', validate, 'warning');
          }
      }
    };

    const showAlert = (title, message, type) => {
      store.commit('alert', {
        display: true,
        title: title,
        message: message,
        type: type
      });
      setTimeout(() => {
        store.commit('alert', {
          display: false,
        });
      }, 6000);
    };

    watch(
      () => [store.getters.modalPromoter.display],
      () => {
        if (store.getters.modalPromoter.display && store.getters.modalPromoter.isEditing) {
          data.value = { ...store.getters.modalPromoter.promoter };
          data.value.evento_id = store.getters.evento.id;
          if (data.value.vinculo) {
            data.value.perm_visualiza_qrcode = data.value.vinculo.perm_visualiza_qrcode;
            data.value.perm_cancel  = data.value.vinculo.perm_cancel;
            data.value.create_link_promoter = data.value.vinculo.perm_link_promoter;
            data.value.link_promoter = data.value.vinculo.link_promoter;
          }
          delete data.value.vinculo;
          delete data.value.id;
          delete data.value.promoters_id;
          delete data.value.created_at;
          delete data.value.updated_at;
          delete data.value.deleted_at;
          delete data.value.promoter;
        } else {
          data.value = {
            nome: "",
            doc: "",
            rg: "",
            email: "",
            telefone: "",
            porcentagem: "",
            instagram: "",
            facebook: "",
            logradouro: "",
            numero: "",
            complemento: "",
            cep: "",
            cidade: "",
            bairro: "",
            uf: "",
            link_promoter: "",
            perm_cancel: true,
            create_link_promoter: true,
            perm_visualiza_qrcode: true,
            evento_id: 0,
            usuario_id: 0,
          };
        }
      })

    return { store, close, salvar, data, procurarPromoter };
  },
}
</script>