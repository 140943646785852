import { decodeJwt } from '../services/util'

export default {

    getEmail() {
        const token = localStorage.getItem('token_meu_evento');

        if (token) {
            const info = decodeJwt(token)

            return info.email

        }

    },

    getInfo() {
        const token = localStorage.getItem('token_meu_evento');

        if (token) {
            const info = decodeJwt(token)

            return info

        }
    },

    getName() {
        // asd
    },

    isPromoter() {
        // asd
    },

    isAdministrator() {
        const token = localStorage.getItem('token_meu_evento');

        if (token) {
            const info = decodeJwt(token)

            return info.admin

        }
    },

    isOrganizador() {
        // asd
    },

    cancelPermission() {
        // asd
    },

    qrcodePermission() {
        // asd
    },


}