import store from '../../store/index'

const EventoInfo = {
    getEvento() {

        const ls = localStorage.getItem('event_santo_cartao')

        if (ls !== null) {
            const eventLS = JSON.parse(ls!)
            store.commit('setEvento', {
                id: eventLS.id,
                nome: eventLS.nome,
                data: eventLS.data,
                data_fim: eventLS.data_fim,
                data_inicio: eventLS.data_inicio,
            })

        } else {
            this.verifyEvento()
            store.commit('setEvento', {
                id: 0,
                nome: "Selecione um evento",
                data: new Date().toLocaleDateString('pt-BR'),
                data_inicio: '',
                data_fim: '',
                year: ""
            })
        }

        return store.getters.evento
    },

    getEventoID() {

        const ls = localStorage.getItem('event_santo_cartao')

        if (ls !== null) {
            const eventLS = JSON.parse(ls!)
            store.commit('setEvento', {
                id: eventLS.id,
                nome: eventLS.nome,
                data: eventLS.data,
                data_fim: eventLS.data_fim,
                data_inicio: eventLS.data_inicio,
            })

        } else {
            this.verifyEvento()

            store.commit('setEvento', {
                id: 0,
                nome: "Selecione um evento",
                data: new Date().toLocaleDateString('pt-BR'),
                data_inicio: '',
                data_fim: '',
                year: ""
            })
        }
        return store.getters.evento.id
    },



    setEvento(value) {
        store.commit('setEvento', {
            id: value.id,
            nome: value.nome,
            data: value.data,
            data_fim: value.data_fim,
            data_inicio: value.data_inicio,
        })
    },

    verifyEvento() {
        const ls = localStorage.getItem('event_santo_cartao')

        if (ls !== null) {
            const eventLS = JSON.parse(ls!)
            store.commit('setEvento', {
                id: eventLS.id,
                nome: eventLS.nome,
                data: eventLS.data,
                data_fim: eventLS.data_fim,
                data_inicio: eventLS.data_inicio,
            })

        } else {
            store.commit('setEvento', {
                id: 0,
                nome: "Selecione um evento",
                data: new Date().toLocaleDateString('pt-BR'),
                year: "",
                data_fim: '',
                data_inicio: '',
            })

            localStorage.setItem('event_santo_cartao', JSON.stringify({
                id: 0,
                nome: "Selecione um evento",
                data: new Date().toLocaleDateString('pt-BR'),
                year: "",
                data_fim: '',
                data_inicio: '',
            })
            )
        }
        return this.getEventoID()
    }
}

export default EventoInfo 