<template>
  <div :class="`w-full  ${store.getters.loading ? 'block' : 'hidden'} fixed w-full h-full top-0 left-0 flex items-center justify-center
  bg-black opacity-70 loading`">
    <img class="w-20 select-none" src='../../assets/images/loader.gif' />
  </div>
</template>

<script>
import { useStore } from 'vuex'
export default {
  name: 'loadingComponent',
  setup() {
    const store = useStore()
    return { store }
  }
}
</script>

<style scoped >
  .loading {
    z-index: 99999999999999999;
  }
</style>