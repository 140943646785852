<template>
  <div
    v-if="store.getters.modalSairRetirada.display"
    x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150"
    x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed inset-0 flex  bg-black bg-opacity-50 items-center justify-center z-50"
  >
    <div
      x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2"
      x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150"
      x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="modalSairRetirada px-6 py-4 bg-white rounded-t-lg dark:bg-gray-800 rounded-lg sm:m-4 sm:w-auto flex flex-col justify-evenly items-center z-50 gap-5"
      role="dialog"
    >
      <h1 class="text-SC_SmallSubtitle font-bold">
        Tem certeza que deseja sair?
      </h1> 
      <div class="flex items-center justify-evenly w-full gap-3">
        <button
          class="btn text-center flex items-center justify-center rounded-md bg-SC_Roxo"
          @click="closeModal"
        >
          Não
        </button>
        <button
          class="btn bg-SC_Gray text-center flex items-center justify-center rounded-md"
          @click="sairRetirada"
        >
          Sim
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "ModalSairRetirada",
  setup: () => {
    const store = useStore();

    const closeModal = () => {
      store.commit("modalSairRetirada", { display: false });
    };

    const sairRetirada = () => {
      store.commit("modalSairRetirada", { display: false });
      store.commit("modalRetirada", { display: false });
    };

    return {
      store,
      closeModal,
      sairRetirada,
    };
  },
};
</script>

<style lang="scss" scoped>
.modalSairRetirada {
  z-index: 99999;
}

.btn {
  height: 30px;
  padding: 4px 8px;
  color: white;
  cursor: pointer;
}
</style>
