<template>
  <template v-if="isMobile">
    <div
      class="bg-SC_LightestGray w-screen fixed bottom-0 left-0 right-0 z-40 h-16 flex items-center justify-evenly overflow-hidden"
      v-if="options.length > 0"
    >
      <template v-if="options.length > 0">
        <ul class="h-auto flex w-full items-center justify-evenly">
          <template v-for="(item, index) in options" :key="index">
            <li v-if="item.show != false" class="">
              <a
                :class="` inline-flex items-center w-full select-none text-sm font-semibold transition-colors duration-150 
              }`"
              >
                <div class="flex justify-between w-full">
                  <router-link
                    class="flex flex-col relative items-center text-SC_Roxo text-SC_Text hover:opacity-100 p-2"
                    :to="{ name: item.name}"
                    v-bind:class="[
                      item.path === route.path ? 'opacity-100' : 'opacity-90',
                    ]"
                  >
                    <fa :icon="`${item.icon}`" size="lg" class="my-1" /> <p style="font-size: 12px; text-align: center;">{{ item.name }}</p>
                    <div
                      class="bg-SC_Roxo h-1 w-full rounded-sm absolute bottom-1 transition-opacity duration-500"
                      v-if="item.path === route.path"
                    ></div>
                  </router-link>
                </div>
              </a>
            </li>
          </template>
        </ul>
      </template>
    </div>
  </template>

  <template v-else>
    <aside
      class="min-h-screen w-44 overflow-y-auto shadow-sm bg-SC_Roxo dark:bg-gray-800 flex-shrink-0 fixed top-0 left-0 bottom-0"
    >
      <div class="text-white dark:text-gray-400 flex flex-col justify-between">
        <div class="list">
          <div
            class="text-lg logo max-h-36 flex justify-center items-center font-bold text-gray-800 dark:text-gray-200"
            href="#"
          >
            <router-link to="/" class="flex justify-center items-center h-48">
              <img
                class="h-10 mb-1"
                src="../../assets/images/logobranco.png"
                alt="Logo"
              />
            </router-link>
          </div>
          <hr class="h-px mt-3 mb-4 bg-white border-1 mx-5" />

          <template v-if="options.length > 0">
            <ul class="mt-4 h-auto">
              <template v-for="(item, index) in options" :key="index">
                <li v-if="item.show != false" class="relative px-6 py-3">
                  <span
                    class="absolute inset-y-0 left-0 w-1 bg-white rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                    v-if="item.path === route.path"
                  ></span>
                  <a
                    :class="` inline-flex items-center w-full select-none text-sm font-semibold transition-colors duration-150 
                    }`"
                  >
                    <div class="flex justify-between w-full">
                      <router-link
                        class="flex items-center text-white subtitle3 hover:opacity-100"
                        :to="{ name: item.name }"
                        v-bind:class="[
                          item.path === route.path
                            ? 'opacity-100'
                            : 'opacity-90',
                        ]"
                      >
                        <div class="w-4">
                          <fa :icon="`${item.icon}`" size="sm" />
                        </div>
                        <span class="ml-2">{{
                          item.label
                        }}</span>
                      </router-link>

                      <i
                        :id="`submenu-${index}`"
                        class="gg-chevron-down hover:text-primary cursor-pointer"
                        @click="handleSubmenu(`submenu-${index}`)"
                        v-if="item.submenu"
                      ></i>
                    </div>
                  </a>
                  <div
                    :id="`list-submenu-${index}`"
                    class="mt-3 menu-closed"
                    v-if="item.submenu"
                  >
                    <template
                      v-for="submenu in item.submenu"
                      :key="submenu.path"
                    >
                      <div class="ml-9 my-3">
                        <router-link
                          class="flex items-center hover:text-SC_Gray"
                          :to="submenu.path"
                        >
                          <span
                            class="hover:text-SC_Gray cursor-pointer show-animation text-sm"
                            >{{ submenu.name }}</span
                          >
                        </router-link>
                      </div>
                    </template>
                  </div>
                </li>
              </template>
            </ul>
          </template>
        </div>
      </div>
    </aside>
  </template>
</template>

<script>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
// import  router  from "../../router";
import Labels from "../../router/labels.ts";
import LabelsPromoter from "../../router/labelsPromoter.ts";
import account from "../../services/account.ts";
export default {
  name: "SidebarComponent",
  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 900 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 900 ? true : false;
    });
  },
  setup() {
    const route = useRoute();

    const options = ref([]);

    onMounted(() => {
      const infoAccount = account.getInfo();

      options.value = [];

      if (infoAccount.type === "admin" || infoAccount.type === "organizador") {
        options.value = Labels;
      } else {
        options.value = LabelsPromoter;
      }
    });

    const handleSubmenu = (item) => {
      if (
        document
          .getElementById(`list-${item}`)
          .classList.contains("menu-closed")
      ) {
        document.getElementById(`list-${item}`).classList.remove("menu-closed");
        document.getElementById(`list-${item}`).classList.add("menu-open");
        document.getElementById(item).classList.remove("gg-chevron-down");
        document.getElementById(item).classList.add("gg-chevron-up");
      } else {
        document.getElementById(`list-${item}`).classList.remove("menu-open");
        document.getElementById(`list-${item}`).classList.add("menu-closed");
        document.getElementById(item).classList.add("gg-chevron-down");
        document.getElementById(item).classList.remove("gg-chevron-up");
      }
    };
    return { options, route, handleSubmenu };
  },
};
</script>

<style scoped>
.gg-dollar {
  margin-left: 5px;
}

.color {
  background: #7d1d54;
}

.logo {
  height: 18%;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.menu-closed {
  display: none;
}

.menu-open {
  display: block;
}

@keyframes showlabels {
  0% {
    margin-top: -20px;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.show-animation {
  animation-name: showlabels;
  animation-duration: 1s;
}

@media only screen and (max-width: 800px) {
  .sidebar {
    position: fixed;
    width: 100vw;
    height: 10vh;
    background-color: #fbca01;
    bottom: 0;
    box-shadow: -5px 0px 20px #fbca01;
  }

  .menu-open {
    position: fixed;
    background-color: black;
    justify-content: center;
    width: 100vw;
    height: 90vh;
    left: 0;
    top: 0;
  }

  .menu-open div {
    margin-left: 0;
  }

  i:hover {
    color: black;
  }

  i {
    color: black;
  }

  .logo {
    display: none;
  }

  .list {
    display: flex;
    align-items: center;
  }

  span {
    margin-left: 0;
  }

  .bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .active {
    color: white;
  }

  i:hover {
    color: black;
  }

  .text-item {
    display: none;
  }

  .sidebar ul {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-open a {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sidebar img {
    display: none;
  }
}
</style>
