import store from '../../store/index';

interface ModalOptions {
    title: string;
    message: string;
    confirmMessage: string;
}

type OkCallback = () => void;
type CancelCallback = () => void;

export default {
    open(title: string, message: string, type: string, confirmMessage: string, callbackOK: OkCallback, callbackCancel?: CancelCallback): void {
        const modalOptions: ModalOptions = {
            title,
            message,
            confirmMessage
        };
        store.commit('modal', {
            display: true,
            ...modalOptions,
            callbackOK,
            callbackCancel
        });
    },
    close(): void {
        store.commit('modal', {
            display: false,
        });
    },
};