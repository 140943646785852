import store from '../../store/index'

export default {
    open(callback=() => {}, msg="Solicitação concluída"){
        store.commit('modalSucessoSuporte',{
            display: true,
            msg: msg,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalSucessoSuporte', {
            display: false,
            msg: "",
            callbackOK: () => {}
        });
    },
}