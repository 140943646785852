import store from '../../store/index'

export default {
    open(callback=() => {}, msg="Deseja realmente sair?"){
        store.commit('modalLogout',{
            display: true,
            msg: msg,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalLogout', {
            display: false,
            msg: "",
            callbackOK: () => {}
        });
    },
}