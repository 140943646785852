const isEmpty = (payload: object)  => {
    const emptyFields: Array<string | void> = []
    Object.entries(payload).forEach(element => {
        if(
            element[1] === "" || element[1] === false
        ){
            emptyFields.push(element[0])
        }
    });
    let returnMessage = ""
    emptyFields.forEach(el => {
        if (el === "doc") el = 'CPF';
        returnMessage += `* O Campo <b>${el}</b> está vazio <br/>`
    })
    return returnMessage
}

const isEmptyUser = (payload: object)  => {
    const emptyFields: Array<string | void> = []
    Object.entries(payload).forEach(element => {
        if(
            element[1] === "" || element[1] === false
        ){
            emptyFields.push(element[0])
        }
    });
    let returnMessage = ""
    emptyFields.forEach(el => {
        if (el === "actualPwd") el = 'Senha atual';
        if (el === "newPwd") el = 'Nova senha';
        if (el === "confirmPwd") el = 'Confirmar nova senha';
        returnMessage += `* O Campo <b>${el}</b> está vazio <br/>`
    })
    return returnMessage
}

const isEmptyPromoter = (payload: object)  => {
    const emptyFields: Array<string | void> = []
    const needLink = payload["create_link_promoter"];
    console.log(payload);
    Object.entries(payload).forEach(element => {
        const mandatory = needLink
            ?
                (element[1] === "" || element[1] === false) &&
                (
                    element[0] === "nome" ||
                    element[0] === "doc" ||
                    element[0] === "rg" ||
                    element[0] === "email" ||
                    element[0] === "cep" ||
                    element[0] === "numero" ||
                    element[0] === "cidade" ||
                    element[0] === "bairro" ||
                    element[0] === "uf" ||
                    element[0] === "porcentagem" ||
                    element[0] === "link"
                )
            :
                (element[1] === "" || element[1] === false) &&
                (
                    element[0] === "nome" ||
                    element[0] === "doc" ||
                    element[0] === "rg" ||
                    element[0] === "email" ||
                    element[0] === "cep" ||
                    element[0] === "numero" ||
                    element[0] === "cidade" ||
                    element[0] === "bairro" ||
                    element[0] === "uf" ||
                    element[0] === "porcentagem"
                )

        if (mandatory) emptyFields.push(element[0]);
    });
    let returnMessage = ""
    emptyFields.forEach(el => {
        if (el === "doc") el = 'CPF';
        returnMessage += `* O Campo <b>${el}</b> está vazio <br/>`
    })
    console.log(returnMessage);
    return returnMessage
}

const isEmptyPagamento = (payload: object)  => {
    const emptyFields: Array<string | void> = []
    Object.entries(payload).forEach(element => {
        if(
            ( element[1] === "" || element[1] === false || element[1] === null) &&
            (
                element[0] === "valor" ||
                element[0] === "saldo" ||
                element[0] === "quantidade" ||
                element[0] === "status" ||
                element[0] === "data"
            )
        ){
            emptyFields.push(element[0])
        }
    });
    let returnMessage = ""
    emptyFields.forEach(el => {
        returnMessage += `* O Campo <b>${el}</b> está vazio <br/>`
    })
    return returnMessage
}

const isEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const isLink = (link: string): boolean => {
    const re = /^[a-z0-9\-_]*$/;
    return re.test(link);
}



export { isEmpty, isEmail, isEmptyPromoter, isEmptyPagamento, isLink, isEmptyUser }