import store from '../../store/index'

export default {
    open(callback=() => {}, msg=""){
        store.commit('modalConfirmation',{
            display: true,
            msg: msg,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalConfirmation', {
            display: false,
            msg: "",
            callbackOK: () => {}
        });
    },
}