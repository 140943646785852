
const numberToCpf = (n: string) => n.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
const numberToCnpj = (n: string) => n.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
const cpfToNumber = (n: string) => n.replace(/[^\w\s]/gi, '');
const toMoney = (n: string) => parseFloat(n).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL'
})
const toDate = (d: string): string => {
    let firstSplit: string;
    let day: string;
    let mounth: string;
    let year: string;
    if (d.length > 0) {
        if (String(d).split("").includes("T")) {
            firstSplit = d.split("T")[0];
            day = firstSplit.split("-")[2];
            mounth = firstSplit.split("-")[1];
            year = firstSplit.split("-")[0];
        } else {
            firstSplit = String(d).split(" ")[0];
            day = firstSplit.split("-")[2];
            mounth = firstSplit.split("-")[1];
            year = firstSplit.split("-")[0];
        }
        return `${day}/${mounth}/${year}`
    }
    return ""
}
const toDateUS = (d: string): string => {
    const firstSplit: string = d.split(" ")[0];
    const day: string = firstSplit.split("-")[2];
    const mounth: string = firstSplit.split("-")[1];
    const year: string = firstSplit.split("-")[0];
    return `${year}-${mounth}-${day}`
}

const dataAtualFormatada = (padrao) => {
    const data = new Date();
    const dia = data.getDate().toString();
    const diaF = (dia.length == 1) ? '0' + dia : dia;
    const mes = (data.getMonth() + 1).toString();
    const mesF = (mes.length == 1) ? '0' + mes : mes;
    const anoF = data.getFullYear();

    if (padrao === "br")
        return `${diaF}/${mesF}/${anoF}`
    else if (padrao === "us")
        return `${anoF}-${mesF}-${diaF}`


}

const adicionarPontuacao = (numero) => {
    // Converter o número para string e dividir em partes de 3 dígitos
    const partes = numero.toString().split('.');
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Juntar novamente as partes do número com a pontuação
    const numeroComPontuacao = partes.join(',');

    return numeroComPontuacao;
}


export const gerarMascaraCNPJ = (cnpj) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/\D/g, '');

    // Aplica a máscara
    cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    return cnpj;
}

export const gerarMascaraCPF = (cpf) => {
    // Remove caracteres não numéricos
    cpf = cpf.replace(/\D/g, '');

    // Aplica a máscara
    cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    return cpf;
}

export const gerarMascaraRG = (rg) => {
    // Remove caracteres não numéricos
    rg = rg.replace(/\D/g, '');

    // Aplica a máscara
    rg = rg.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');

    return rg;
}

export { numberToCpf, cpfToNumber, toDate, toDateUS, toMoney, numberToCnpj, dataAtualFormatada, adicionarPontuacao }