<template>
  <div class="grid grid-cols-12 grid-row-6 gap-4 justify-between">
    <div class="col-start-1 col-span-4 flex items-end row-start-3 row-span-2">
      <img class="img" :src="`https://santocartao-files.s3.amazonaws.com/event-pictures/${evento.id}.jpg`"
        url="https://meuevento.santocartao.com.br/images/no_image.jpg" />
      <div class="my-2">
        <h3 class="mx-2 title1 textColor">
          {{ evento.nome }}
        </h3>
        <div class="flex justify-start">
          <p class="mx-2 title2">Data:</p>
          <span class="title2 bg-gray-200 border mx-1 rounded-lg  px-1">{{ evento.data }}</span>
        </div>
      </div>
    </div>

    <div class="col-start-3 col-end-12 row-start-3 row-span-2 flex items-center justify-end bodytitle">
      <div class="flex justify-end gap-4 w-full items-center">
        <button color="white" text="Voltar para versão anterior"
          class="button botaoVersaoAnterior" @click="voltarVersao" v-if="mostrarBotao">
          Voltar para versão anterior
        </button>
        
        <Button color="secondary" text="Trocar Evento" @click="changeEvent"
          class="button bg-secondary hover:bg-SC_Roxo flex" style="padding-right: 20px;padding-left: 20px; border-radius: 10px;"/>
        <div class="flex flex-col">
          <div class="flex items-center gap-1 cursor-pointer" @click="handleDrop">
            <h4 class="text-SC_Gray bodytitle">
              {{ userInfo }}
            </h4>
            <fa icon="caret-down" class="text-SC_Azul" v-if="!store.getters.headerDropdown.display" />
            <fa icon="caret-up" class="text-SC_Azul" v-else />
          </div>
          <HeaderDropdown v-if="store.getters.headerDropdown.display" class="fixed w-52 top-20 right-24" />
        </div>
      </div>
    </div>

    <!--<hr class="my-9 row-start-5 col-start-1 col-end-12" />-->
  </div>
</template>

<script>
import Button from "../Elements/Button.vue";
import { inject, ref, onMounted, watch, defineComponent } from "vue";
import HeaderDropdown from "./HeaderDropdown.vue";
import { useStore } from "vuex";
import { GET } from "@/services/api";
import router from "@/router";

export default defineComponent({
  name: "HeaderComponent",
  components: {
    Button,
    HeaderDropdown,
  },

  setup() {
    const modalTrocaEventos = inject("modalTrocaEvento");
    const eventoInfo = inject("evento");
    const account = inject("account");
    const dropdown = inject("headerDropdown");
    const userInfo = ref("");
    const store = useStore();
    const loader = inject("loading");
    const mostrarBotao = ref(false)

    const evento = ref({});

    const verificaEventoBotaoLegado = async () => {
      try {
      loader.open()
      const getEvento = await GET(`event/funcionalidade/${eventoInfo.getEvento().id}`);
      
      getEvento.map((x) => {
        if(x.type === 'nova_funcionalidade')
          mostrarBotao.value = true
      })

      }
      catch (e){
        console.log(e)
      } finally {
        loader.close()
      }
    }
    

    const voltarVersao = async () => {
      try {
      loader.open()
      const getUser = await GET(`usuarios/getAllInfo/${account.getInfo().id}`);
      const userCripto = btoa(JSON.stringify({
        user:getUser.user.usuario,
        password: getUser.user.senha
      }))
      window.location.href = `https://meuevento.santocartao.com.br/login?login=${userCripto}`
      }
      catch (e){
        console.log(e)
      } finally {
        loader.close()
      }
    }

    const eventoValues = () => {
      evento.value = eventoInfo.getEvento();

      if (eventoInfo.getEventoID() === 0) {
        modalTrocaEventos.open();
      }
    };

    const handleDrop = () => {
      store.commit("headerDropdown", {
        display: !store.getters.headerDropdown.display,
      });
    };

    onMounted(() => {
      verificaEventoBotaoLegado()
      userInfo.value = account.getEmail();
      eventoValues();
    });

    const changeEvent = () => {
      modalTrocaEventos.open();
    };

    watch(
      () => eventoInfo.getEventoID(),
      () => {
        eventoValues();
      }
    );

    return {
      changeEvent,
      evento,
      userInfo,
      handleDrop,
      store,
      voltarVersao,
      mostrarBotao
    };
  },
});
</script>

<style lang="scss" scoped>
.img {
  border: 5px solid #361d54;
  border-radius: 540px;
  width: 72px;
  height: 72px;
  object-fit: cover;
  object-position: 100% 5;
}

.textColor {
  color: #361d54;
}

.button {
  height: 40px;
}

.botaoVersaoAnterior {
  background-color: transparent;
  border: 1px solid #211261;
  color: #211261;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold; 
}

.botaoVersaoAnterior:hover {
  background-color: #211261;
  color: white;
}
</style>
