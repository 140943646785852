<template>

    <div v-if="store.getters.modalLogout.display"
      x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0"
      x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150"
      x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0"
      class="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    
      <div x-transition:enter="transition ease-out duration-150"
        x-transition:enter-start="opacity-0 transform translate-y-1/2"
        x-transition:enter-end="opacity-100"
        x-transition:leave="transition ease-in duration-150"
        x-transition:leave-start="opacity-100"
        x-transition:leave-end="opacity-0  transform translate-y-1/2"
        class="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
        role="dialog"
      >
        <div>
          <h6 class="text-center">Atenção!</h6>
          <div v-html="store.getters.modalLogout.msg" class="text-center"></div>
          <div class="buttons">
            <Button @click="close" color="gray-500" text="Não" />
            <Button @click="confirmar" color="primary" text="Sim" />
          </div>
        </div>
    
      </div>
    
    </div>
    
    </template>
    
    <script lang="ts">
    
    import { useStore } from "vuex";
    
    import Button from '../Elements/Button.vue'
    import { useRouter } from 'vue-router';
    
    export default ({
      name: "modalLogout",
      components: {
        Button,
      },
    
      setup() {
        const store = useStore();
        const router = useRouter();
    
        const close = () => {
          store.commit("modalLogout", { display: false, callbackOK: () => {}, msg: "" });
        };
    
        const confirmar = async () =>{         
            localStorage.removeItem('event_santo_cartao')
            localStorage.clear()
            store.commit("modalLogout", { display: false, callbackOK: () => {}, msg: "" });
            router.push('/login');
        };
    
        return { store, close, confirmar };
      },
    })
    </script>
    
<style scoped>
    
    .modal-overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      background-color: #000000da;
    }
    
    .modal {
      text-align: center;
      background-color: white;
      height: 500px;
      width: 500px;
      margin-top: 10%;
      padding: 60px 0;
      border-radius: 20px;
    }
    .close {
      margin: 10% 0 0 16px;
      cursor: pointer;
    }
    
    .close-img {
      width: 25px;
    }
    
    .check {
      width: 150px;
    }
    
    h6 {
      font-weight: 500;
      font-size: 28px;
      margin: 20px 0;
    }
    
    p {
      font-size: 16px;
      margin: 20px 0;
    }
    
    
    .confirmar{
      width: 98%;
      display: inline-block;
      margin: 5px;
    }
    
    .buttons{
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
    }
    
    .msg{
      font-size: 12px;
      color:red;
    }
    
    .input{
      display: grid;
      
      margin-top: 25px;
      margin-bottom: 2px;
      width: 100%;
    }
    </style>
    