<template>
  <div class="absolute mt-2">
    <div class="bg-white rounded-sm p-4 shadow-lg flex flex-col gap-2">
      <router-link :to="path"  >
        <p
          class="text-SC_Gray hover:text-SC_Azul hover:underline gap-2 flex items-center cursor-pointer"
        >
          <fa icon="user" class="text-sm" />
          Minha Conta
        </p>
      </router-link>
      <hr />
      <p
        class="text-red-500 hover:underline gap-2 flex items-center cursor-pointer"
        @click="logout"
      >
        <fa icon="arrow-right-from-bracket" class="text-sm" />
        Sair
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import account from "@/services/account";

export default defineComponent({
  name: "HeaderDropDown",
  props: {
    method: { type: Function },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const path = ref(`${account.getInfo().type == 'promoter' ? '/perfil-promoter' : '/perfil'}`);
    const modalLogout = inject("modalLogout");

    const logout = () => {
      modalLogout.open();
      /*store.commit("headerDropdown", {
        display: false,
      });
      localStorage.clear();
      router.push("/login");*/
    };

    return { store, props, logout, path };
  },
});
</script>

<style scoped></style>
