<template>
  <button
    v-if="outline"
    type="button"
    :class="`btn-outline-primary 
    transition duration-300 ease-in-out 
    bg-white text-${color}
    focus:outline-none focus:shadow-outline border border-${color}
    font-bold py-2 px-4 text-sm rounded-md flex items-center
    `"
  >
    {{text}} 
  </button>
  <button
    v-else
    type="button"
    :class="`btn-outline-primary 
    transition duration-300 ease-in-out 
    bg-${color} text-white
    focus:outline-none focus:shadow-outline border
    font-bold py-2 px-4 text-sm rounded-md flex items-center
    `"
  >
    {{text}} 
  </button>
</template>

<script>
export default {
  name: "ButtomComponent",
    props:{
        color: String || null,
        text: String || null,
        outline: Boolean || null
    },
    setup(props){
        return {props}
    }
};
</script>

<style>
</style>