import { createStore } from "vuex";

const store = createStore({
  state: {
    name: "Vue",
    alert: {
      display: false,
      title: "",
      message: "",
      type: "",
    },
    modal: {
      display: false,
      title: "",
      message: "",
      input: "",
      confirmMessage: "",
      callbackOK: "",
      callbackCancel: "",
    },
    modalIngresso: {
      display: false,
      ingresso: "",
    },
    modalConfirmation: {
      display: false,
      msg: "",
      callbackOK: () => {},
    },
    modalLogout: {
      display: false,
      msg: "",
      callbackOK: () => {},
    },
    modalSucessoSuporte: {
      display: false,
      msg: "",
      callbackOK: () => {},
    },

    modalTrocaEvento: {
      display: false,
    },

    loading: false,

    userid: "",

    account: {
      id: 0,
      email: "",
      admin: false,
    },

    evento: {
      id: 0,
      nome: "",
      data: "",
      data_inicio: "",
      data_fim: "",
      year: "",
    },

    headerDropdown: {
      display: false,
    },

    filters: {
      data: [],
    },

    printReport: {
      data: []
    },

    canPrint: false,

    modalRetirada: {
      display: false
    },

    dadosFinanceiro: {
      valorTotal: 0,
      retiradas: [],
      pagamentos: 0,
      provisionamento: [],
      calendario: []
    },

    modalSairRetirada: {
      display: false
    },

    modalRetiradaConcluida: {
      display: false
    },

    modalPromoter: {
      display: false,
      isEditing: false,
      promoter: {}
    },

    modalValorPago: {
      display: false,
      valores: []
    },

    modalExtrato: {
      display: false,
      promoter: {},
      valores: []
    },

    modalPagamento: {
      display: false,
      dados_pagamento: {},
      promoter: {}
    },

    modalCadastroPromoters: {
      display: false,
      evento: {},
    },

    updateTablePromoter: false,
  },

  getters: {
    alert: (state) => state.alert,
    loading: (state) => state.loading,
    modal: (state) => state.modal,
    modalTrocaEvento: (state) => state.modalTrocaEvento,
    modalConfirmation: (state) => state.modalConfirmation,
    modalLogout: (state) => state.modalLogout,
    modalSucessoSuporte: (state) => state.modalSucessoSuporte,
    modalIngresso: (state) => state.modalIngresso,
    user: (state) => state.userid,
    evento: (state) => state.evento,
    account: (state) => state.account,
    headerDropdown: (state) => state.headerDropdown,
    filters: (state) => state.filters,
    printReport: (state) => state.printReport,
    canPrint: (state) => state.canPrint,
    modalRetirada: (state) => state.modalRetirada,
    dadosFinanceiro: (state) => state.dadosFinanceiro,
    modalSairRetirada: (state) => state.modalSairRetirada,
    modalRetiradaConcluida: (state) => state.modalRetiradaConcluida,
    modalPromoter: (state) => state.modalPromoter,
    modalValorPago: (state) => state.modalValorPago,
    modalExtrato: (state) => state.modalExtrato,
    modalPagamento: (state) => state.modalPagamento,
    modalCadastroPromoters: (state) => state.modalCadastroPromoters,
    updateTablePromoter: (state) => state.updateTablePromoter,
  },

  mutations: {
    changeName: (state, value) => (state.name = value),
    loading: (state, value) => (state.loading = value),
    alert: (state, value) => (state.alert = value),
    modal: (state, value) => (state.modal = value),
    modalIngresso: (state, value) => (state.modalIngresso = value),
    modalConfirmation: (state, value) => (state.modalConfirmation = value),
    modalLogout: (state, value) => (state.modalLogout = value),
    modalSucessoSuporte: (state, value) => (state.modalSucessoSuporte = value),
    modalTrocaEvento: (state, value) => (state.modalTrocaEvento = value),
    modalPromoter: (state, value) => (state.modalPromoter = value),
    modalValorPago: (state, value) => (state.modalValorPago = value),
    modalExtrato: (state, value) => (state.modalExtrato = value),
    user: (state, value) => (state.userid = value),
    updateTablePromoter: (state, value) => (state.updateTablePromoter = value),

    setEvento: (state, value) => (state.evento = value),
    setAccount: (state, value) => (state.account = value),
    headerDropdown: (state, value) => (state.headerDropdown = value),
    filters: (state, value) => (state.filters = value),
    printReport: (state, value) => (state.printReport = value),
    canPrint: (state, value) => (state.canPrint = value),
    modalRetirada: (state, value) => (state.modalRetirada = value),
    dadosFinanceiro: (state, value) => (state.dadosFinanceiro = value),
    modalSairRetirada: (state, value) => (state.modalSairRetirada = value),
    modalRetiradaConcluida: (state, value) => (state.modalRetiradaConcluida = value),
    modalPagamento: (state, value) => (state.modalPagamento = value),
    modalCadastroPromoters: (state, value) => (state.modalCadastroPromoters = value),
  },
});

export default store;
