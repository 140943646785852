import store from '../../store/index'


export default {
    open(evento={}){
        store.commit('modalCadastroPromoters',{
            display: true,
            evento: evento
        })
    },
    close(){
        store.commit('modalCadastroPromoters', {
            display: false,
            evento: {}
        });
    },
}