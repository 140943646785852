import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6255014a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "bg-gray-50"
}
const _hoisted_2 = { class: "flex flex-col h-screen" }
const _hoisted_3 = { class: "flex-1" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "grid grid-cols-12 grid-rows-6 bg-white h-screen w-screen overflow-x-hidden" }
const _hoisted_6 = { class: "col-span-2 col-start-1 row-span-6" }
const _hoisted_7 = { class: "col-start-3 col-span-12 row-span-1" }
const _hoisted_8 = { class: "col-start-3 col-span-12 row-span-5 pt-8" }
const _hoisted_9 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_Header = _resolveComponent("Header")!

  return (_ctx.isMobile)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_navbar),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view)
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_sidebar)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Header)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_router_view)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_navbar)
          ])
        ])
      ]))
}