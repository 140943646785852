<template>
  <div :class="`${mobile
    ? 'w-full rounded-xl shadow-xs'
    : 'w-full overflow-hidden rounded-lg shadow-xs'
    }`">
    <div class="w-full overflow-x-auto">
      <div :class="`bg-gray-50 py-3 flex justify-between ${mobile ? 'w-screen' : 'w-full'}`">
        <select v-model="perView"
          class="block px-3 mt-1 text-sm border bg-white form-select focus:border-gray-200 focus:outline-none focus:shadow-outline-green dark:focus:shadow-outline-gray">
          <option v-for="option of optionsViews" :key="option.text" :value="option.value">
            {{ option.text }}
          </option>
        </select>
        <div>
          <input v-model="query" class="border rounded-sm p-2 text-sm" placeholder="Filtrar" />
          <!--<button v-if="exports" class="text-sm bg-primary p-2 rounded-sm text-white ml-3" @click="exportCSVFile(headers, copyData, 'table')">Exportar para <b>CSV</b></button>-->
        </div>
      </div>
      <hr />
      <table v-if="copyData && data && copyData.length > 0" class="w-full h-full whitespace-no-wrap">
        <thead>
          <tr
            class="text-SC_SmallSubtitle font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
            <template v-for="header in headers" :key="header.value">
              <th class="px-4 py-4 text-SC_SmallSubtitle">
                <div class="flex items-center cursor-pointer text-SC_SmallSubtitle" @click="() =>
                  sortTable(
                    stateSort == 'dec' ? 'asc' : 'dec',
                    header.value,
                    header.isDate
                      ? 'date'
                      : typeof copyData[0][header.value]
                  )
                  ">
                  {{ header.key }}
                  <i class="gg-swap-vertical ml-2"></i>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody class="bg-white dark:divide-gray-700 dark:bg-gray-800">
          <template v-for="(item, index) in copyData" :key="item">
            <tr :class="`text-gray-700 ${index % 2 != 0 ? 'bg-gray-100' : ''
              } dark:text-gray-400 text-SC_Text`">
              <template v-for="object in headers" :key="object">
                <td v-if="object.value != '' && object.photo" :class="`px-2 py-2 text-sm `">
                  <div class="flex-column relative h-full justify-center items-center">
                    <img class="w-20 rounded-md" :src="item[object.value]" />
                  </div>
                </td>
                <template v-else>
                  <td v-if="object.value != ''" :class="`px-4 py-4 text-sm `">
                    <span v-if="object.date">
                      {{
                        object.deep
                          ? toDate(deep(item, object.value))
                          : toDate(item[object.value])
                      }}
                    </span>

                    <span v-else-if="object.dateHour">
                      {{ moment(item[object.value]).format("DD/MM/YYYY HH:mm:ss") }}
                    </span>

                    <span v-else-if="object.sales">
                      <div>{{ item[object.value[1]] }} ingressos</div>
                      <div class="font-bold">{{ toMoney(item[object.value[0]]) }}</div>
                    </span>

                    <span v-else-if="object.download">
                      <button v-if="item.comprovante" class="text-gray-700 border-none hover:text-primary"
                        @click="object.click(item)">
                        <i :class="`gg-software-download`" :title="object.title"></i>
                      </button>
                      <button v-else class="text-gray-700 border-none hover:text-primary hidden">
                        <i :class="`gg-software-download`" :title="object.title"></i>
                      </button>
                    </span>
                    <span v-else-if="object.relatorio">
                      <button class="text-gray-700 border-none hover:text-primary mx-1" @click="object.action(item)">
                        <i :class="`gg-file-document`" :title="object.title"></i>
                      </button>

                      <button v-if="item.relatorio" class="text-gray-700 border-none hover:text-primary mx-1"
                        @click="object.click(item)">
                        <i :class="`gg-software-download`" title="Download Relatorio"></i>
                      </button>

                      <button v-else class="text-gray-700 border-none hover:text-primary mx-1 hidden"
                        @click="object.click(item)">
                        <i :class="`gg-software-download`" title="Download Relatorio"></i>
                      </button>
                    </span>

                    <span v-else-if="object.trash">
                      <button v-if="item.tipo_produto != 103"
                        class="text-gray-700 border-none hover:text-primary mx-1 flex justify-center"
                        @click="object.action(item)">
                        <i :class="`gg-trash`" :title="object.title"></i>
                      </button>
                    </span>

                    <span v-else-if="object.qrcode">
                      <button v-if="item.tipo_produto != 103" class="text-gray-700 border-none hover:text-primary mx-1"
                        @click="object.action(item)">
                        <i :class="`gg-qr`" :title="object.title"></i>
                      </button>
                    </span>

                    <span v-else-if="object.carrinho">
                      <button class="text-gray-700 border-none hover:text-primary mx-1" @click="object.action(item)">
                        <i :class="`gg-arrow-right-o`" :title="object.title"></i>
                      </button>
                    </span>
                    <span v-else-if="object.money" :class="`${creditoDebito ? `text-${item['creditoDebito']}-500` : ''
                      }`">
                      {{ toMoney(item[object.value]) }}
                    </span>
                    <span v-else-if="object.color">
                      <div :style="`height: 25px; width: 25px; background-color: ${item[object.value]
                        }; border-radius: 5px`"></div>
                    </span>
                    <span v-else-if="object.document">
                      <template v-if="item[object.value].length > 11">
                        {{ numberToCnpj(item[object.value]) }}
                      </template>
                      <template v-else>
                        {{ numberToCpf(item[object.value]) }}
                      </template>
                    </span>
                    <span v-else :class="` ${object.badge
                      ? `px-3 py-1 rounded-full text-white capitalize bg-${object.badge[item[object.value]]
                      }-500`
                      : ''
                      }`">{{
                        object.deep
                          ? deep(item, object.value).length > 0
                            ? deep(item, object.value)
                            : ""
                          : object.badge
                            ? item[object.value] === 1
                              ? "Ativado"
                              : item[object.value]
                            : item[object.value]
                      }}
                    </span>
                  </td>
                </template>
              </template>
              <td v-if="props.options && props.options.length > 0"
                class="flex relative h-full items-center px-4 py-4 text-sm">
                <div class="flex h-full absolute top-0 justify-start items-center space-x-4 text-sm">
                  <template v-for="option in props.options" :key="option.icon">
                    <div @click="option.action(item)">
                      <i :class="`fa fa-${option.icon}`" :style="{ color: option.color }" :title="option.title"></i>
                    </div>
                  </template>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <template v-else>
        <div
          class="bg-gray-100 text-center font-medium py-3 flex justify-center items-center opacity-25 text-SC_Subtitle ">
          Nenhum item encontrado... <i class="ml-3 gg-ghost-character"></i>
        </div>
      </template>

      <div
        :class="`px-4 py-3 flex justify-between items-center text-xs font-semibold w-full h-full  text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800`">

        <span v-if="data" class="flex items-center text-SC_Text">
          Mostrando {{ indexPagination * perView }} -
          {{ copyData ? copyData.length : 0 + indexPagination * perView }} de
          {{ meta ? ` ${meta.itemCount} ` : data ? data.length : 0 }}
        </span>

        <div class="flex items-center">
          <button :class="`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-${color}`"
            aria-label="Previous" v-if="indexPagination > 0" @click="async () => {
              indexPagination--;
            }">
            <svg aria-hidden="true" class="w-4 h-4 fill-current" viewBox="0 0 20 20">
              <path
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd" fill-rule="evenodd"></path>
            </svg>
          </button>

          <template v-for="position in sizeOfList" :key="position">
            <button v-if="position - 1 == indexPagination"
              :class="`px-3 py-1 text-white transition-colors duration-150 bg-${color} border border-r-0 border-${color} rounded-md focus:outline-none focus:shadow-outline-${color}`">
              {{ position }}
            </button>
            <button v-else-if="position - 1 <= indexPagination + 3 && position > indexPagination - 1" @click="async () => {
              indexPagination = position - 1;
            }" :class="`px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-${color}`">
              {{ position }}
            </button>
          </template>

          <button :class="`px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-${color}`"
            aria-label="Next" v-if="(meta && meta.hasNextPage) || indexPagination < sizeOfList - 1" @click="async () => {
              indexPagination++;
            }">
            <svg class="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
              <path
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd" fill-rule="evenodd"></path>
            </svg>
          </button>
        </div>
      </div>



    </div>
  </div>
</template>

<script lang="ts">
type MetaType = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemCount: number;
  page: number;
  pageCount: number;
  take: number;
};

import { watch, ref, PropType, defineEmits, onMounted, onUpdated } from "vue";
import moment from "moment-timezone";
import {
  toDate,
  toMoney,
  numberToCpf,
  numberToCnpj,
} from "../../services/formater";
export default {
  name: "DataTableComponent",
  props: {
    headers: Array as PropType<any[]>,
    data: Array as PropType<any[]>,
    options: Array as PropType<any[]>,
    meta: Object as PropType<MetaType>,
    color: String,
    exports: Boolean,
    mobile: Boolean,
    creditoDebito: Boolean,
  },
  setup(props: any, ctx) {
    const query = ref("");
    const copyData: any = ref();
    const perView = ref(10);
    const pagination = ref();
    const indexPagination = ref(0);
    const sizeOfList = ref();
    const stateSort = ref("asc");
    const optionsViews = ref([
      { text: "10", value: "10" },
      { text: "25", value: "25" },
      { text: "50", value: "50" },
      { text: "100", value: "100" },
    ]);

    const deep = (item: object, pos: string): any => {
      let payload = item;
      pos.split("][").map((el) => {
        payload = payload[el.replace(/[^\w\s]/gi, "")]
          ? payload[el.replace(/[^\w\s]/gi, "")]
          : payload;
      });

      return payload;
    };

    const find = (query: string) => {
      const response: Array<any | null> = [];
      props.data.map((item: object, index: number) => {
        Object.entries(item).map((element) => {
          if (!response.includes(props.data[index])) {
            if (String(element[1]).toLowerCase() === query.toLowerCase()) {
              response.unshift(props.data[index]); //Items Iguais!
            } else {
              if (
                String(element[1]).toLowerCase().includes(query.toLowerCase())
              ) {
                response.push(props.data[index]); //Items semelhantes!
              }
            }
          }
        });
      });
      return response;
    };

    const divide = (payload: Array<any> | any, divider: number | any) => {


      const divideArray = [...payload];



      return new Array(Math.ceil(divideArray.length / divider))
        .fill(null)
        .map(() => divideArray.splice(0, divider));
    };

    function converterParaData(dataStr) {
      const [dataPart, horaPart] = dataStr.split(" ");
      const [dia, mes, ano] = dataPart.split("/");
      const [hora, minuto] = horaPart.split(":");
      return new Date(ano, mes - 1, dia, hora, minuto, 0);
    }


    const sortTable = (type: string, key: string, param: any) => {
      stateSort.value = type;
      const sortData: Array<any> = [];

      pagination.value.map((el: Array<any>) => {
        el.map((item: object) => {
          sortData.push(item);
        });
      });
      //return new Date(b.date) - new Date(a.date);
      if (param == "number") {
        if (type == "dec") {
          sortData.sort((a: any, b: any) => b[key] - a[key]);
        } else {
          sortData.sort((a: any, b: any) => a[key] - b[key]);
        }
      } else if (param == "date") {
        if (type == "dec") {
          sortData.sort((a: any, b: any) => {
            const dataA: any = converterParaData(a.data);
            const dataB: any = converterParaData(b.data);

            return dataB - dataA;
          });
        } else {
          sortData.sort((a: any, b: any) => {
            const dataA: any = converterParaData(a.data);
            const dataB: any = converterParaData(b.data);
            return dataA - dataB;
          });
        }
      } else {
        if (type == "dec") {
          sortData.sort((a: any, b: any) =>
            String(b[key]).localeCompare(String(a[key]))
          );
        } else {
          sortData.sort((a: any, b: any) =>
            String(a[key]).localeCompare(String(b[key]))
          );
        }
      }

      pagination.value = divide(sortData, Number(perView.value));



      copyData.value = pagination.value[0];
    };

    watch(
      props,
      () => {
        copyData.value = props.data;

        const divideData = divide(props.data, Number(perView.value));
        copyData.value = divideData[0];
        pagination.value = divideData;
        sizeOfList.value =
          (props.meta && props.meta.pageCount) || divideData.length;

        indexPagination.value = (props.meta && props.meta.page - 1) || 0;
      },
      { deep: true }
    );

    watch(query, async () => {
      const result = find(query.value);
      if (result.length > 0) {
        pagination.value = divide(result, perView.value);
        copyData.value = pagination.value[0];
      } else {

        copyData.value = [];
      }
    });

    watch(perView, async () => {
      const divideData = divide(props.data, Number(perView.value));


      pagination.value = divideData;
      copyData.value = pagination.value[0];
      indexPagination.value = (props.meta && props.meta.page - 1) || 0;
      sizeOfList.value =
        (props.meta && props.meta.pageCount) || divideData.length;
    });

    watch(indexPagination, () => {
      copyData.value = pagination.value[indexPagination.value];
    });


    onMounted(() => {
      copyData.value = props.data;

      const divideData = divide(props.data, Number(perView.value));
      copyData.value = divideData[0];
      pagination.value = divideData;
      sizeOfList.value =
        (props.meta && props.meta.pageCount) || divideData.length;

      indexPagination.value = (props.meta && props.meta.page - 1) || 0;
    })

    return {
      query,
      props,
      copyData,
      perView,
      pagination,
      indexPagination,
      sizeOfList,
      moment,
      sortTable,
      stateSort,
      toDate,
      toMoney,
      numberToCpf,
      numberToCnpj,
      deep,
      optionsViews,
    };
  },
};
</script>
<style scoped></style>
