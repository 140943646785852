const ListPromoter = [
  {
    icon: "home",
    label: "Dashboard",
    name: "PromoterDashboard",
    path: "/dashboard_promoter",
    component: () =>
      import("../views/Views_Promoter/dashboard/promoterDashboard.vue"),
  },
  // {
  //   icon: "paper-plane",
  //   label: "Ingressos",
  //   name: "IngressosPromoter",
  //   path: "/ingressos_promoter",
  //   component: () =>
  //     import("../views/Views_Promoter/ingressos/promoterIngressos.vue"),
  // },
  {
    icon: "dollar",
    label: "Minhas Vendas",
    name: "minhasVendas",
    path: "/minhas_vendas",
    component: () =>
      import("../views/Views_Promoter/minhasVendas/index.vue"),
  },
  {
    show: window.innerWidth < 800,
    icon: 'user',
    name: "Minha Conta Promoter",
    path: "/perfil-promoter",
    component: () => import("../views/Views_Promoter/Perfil/index.vue")
  },
  {
    label: "Emitir Ingresso",
    name: "Voucher Promoter/Novo",
    path: "/voucher_promoter/novo",
    component: () => import("../views/Views_Promoter/ingressos/promoterEmitirIngresso.vue"),
    show: false
  },
  {
    show: false,
    name: "Detalhes da Venda",
    path: "/minhas_vendas/:id/detalhes",
    component: () =>
      import("../views/Views_Promoter/minhasVendas/detalhes.vue"),
  },
];

export default ListPromoter;
